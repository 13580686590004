// Load CSS Reset from NPM
@import "normalize.css";
@import "./_venobox.css";

// @import "./odometer.css";
// @import "./downloadassets.scss";

/* CSS Variables */
:root {
  --green: #00a543;
  --orange: #ffb238;
  --blue: #3e86c4;
  --burnt: #dc783a;
  --red: #cd040b;
}

// Fonts
@font-face {
  font-family: "Verizon NHG DS";
  src: url("./fonts/VerizonNHGDS-Bold.woff2") format("woff2"), url("./fonts/VerizonNHGDS-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG DS";
  src: url("./fonts/VerizonNHGDS-BoldItalic.woff2") format("woff2"),
    url("./fonts/VerizonNHGDS-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG DS";
  src: url("./fonts/VerizonNHGDS-Bold.woff2") format("woff2"), url("./fonts/VerizonNHGDS-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG DS";
  src: url("./fonts/VerizonNHGDS-Italic.woff2") format("woff2"), url("./fonts/VerizonNHGDS-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG DS";
  src: url("./fonts/VerizonNHGDS-Regular.woff2") format("woff2"),
    url("./fonts/VerizonNHGDS-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG DS";
  src: url("./fonts/VerizonNHGDS-BoldItalic.woff2") format("woff2"),
    url("./fonts/VerizonNHGDS-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG TX";
  src: url("./fonts/VerizonNHGTX-Bold.woff2") format("woff2"), url("./fonts/VerizonNHGTX-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG DS";
  src: url("./fonts/VerizonNHGDS-Italic.woff2") format("woff2"), url("./fonts/VerizonNHGDS-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG TX";
  src: url("./fonts/VerizonNHGTX-BoldItalic.woff2") format("woff2"),
    url("./fonts/VerizonNHGTX-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG DS";
  src: url("./fonts/VerizonNHGDS-Regular.woff2") format("woff2"),
    url("./fonts/VerizonNHGDS-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG TX";
  src: url("./fonts/VerizonNHGTX-Bold.woff2") format("woff2"), url("./fonts/VerizonNHGTX-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG TX";
  src: url("./fonts/VerizonNHGTX-BoldItalic.woff2") format("woff2"),
    url("./fonts/VerizonNHGTX-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG TX";
  src: url("./fonts/VerizonNHGTX-Italic.woff2") format("woff2"), url("./fonts/VerizonNHGTX-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Verizon NHG TX";
  src: url("./fonts/VerizonNHGTX-Italic.woff2") format("woff2"), url("./fonts/VerizonNHGTX-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

//Animations
@keyframes drawBorder {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes drawBorderVertical {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

///////////////////////////////////
// General Styles
///////////////////////////////////
html {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
}
body {
  ::selection {
    color: white;
    background-color: #3399fe;
  }
  padding-top: 63px;
  @media only screen and (max-width: 512px) {
    padding-top: 63px;
  }
}
.hidden {
  display: none !important;
}

//////////////////////
// Variables
//////////////////////

$green: #06ac4b;
$red: var(--red);
//////////////////////
// Mixins
//////////////////////
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.sixteen-nine {
  @include aspect-ratio(16, 9);
}

// Fluid Type

@mixin fluid-type($font-min, $font-max, $screen-min, $screen-max) {
  font-size: #{$font-min}px !important;

  @media only screen and (min-width: #{$screen-min}px) {
    font-size: calc(
      #{$font-min}px + #{($font-max - $font-min)} * (100vw - #{$screen-min}px) / (#{$screen-max} - #{$screen-min})
    ) !important;
  }

  @media only screen and (min-width: #{$screen-max}px) {
    font-size: #{$font-max}px !important;
  }
}

// Lazyload
.lazyload {
  opacity: 0;
  transition: all 500ms;
  background-repeat: no-repeat;
  background-size: cover;
}
.hqy-loaded {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.lozad {
  opacity: 0;
  transition: all 500ms;
  background-repeat: no-repeat;
  background-size: cover;
}
.lozad[data-loaded="true"] {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.img-fluid {
  max-width: 100%;
}

.br-desktop {
  @media (min-width: 680px) {
    display: block;
  }
}

// Main Styles
//////////////////////
// Fonts & things
//////////////////////
h1 {
  font-family: "Verizon NHG DS";
  @include fluid-type(40, 64, 400, 1450);
  letter-spacing: -1px;
  line-height: 1em !important;
  margin: 0.25em 0;
  &.hero {
    overflow: hidden;
    @include fluid-type(48, 96, 400, 1450);
    font-weight: bold;
    @media (max-width: 598px) {
      //  font-size: 30px !important;
    }
  }
}

h1.hero span {
  display: inline-block;
}
@media only screen and (max-width: 768px) {
  h1.hero {
    max-width: 300px;
  }
}
@media (max-width: 544px) {
  h1.hero {
    max-width: 250px;
  }
}
p {
  overflow: hidden;
}
h1.activate,
span.activate,
.activate {
  opacity: 0;
  transform: translateY(20px);
  transition: 0.7s ease;
  &.activated {
    opacity: 1 !important;
    transform: translateY(0px);
    transition: 0.7s ease;
  }
}

h2.bullet {
  font-family: "Verizon NHG DS";
  @include fluid-type(30, 36, 400, 1450);
  font-weight: bold;
  color: var(--green);
}
h3.subhead {
  font-family: "Verizon NHG DS";
  @include fluid-type(22, 26, 400, 1450);
  color: var(--green);
  margin-top: 0px;
  margin-bottom: 0em;
}

p {
  font-size: 24px;
  font-family: "Verizon NHG DS";
  line-height: 1.25em;
  &.lead {
    font-size: 24px;
    line-height: 1.5em;
    line-height: 28px;

    font-weight: 100;
  }
  &.smaller {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.content-overlay-content h2 {
  font-family: "Verizon NHG DS";
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 0px;
  @media only screen and (max-width: 1100px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 950px) {
    font-size: 14px;
  }
  margin-top: 0px !important;
  letter-spacing: 0.25px;
  line-height: 36px;
  font-weight: bold !important;

  @media only screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.white-text {
  color: white;
}
.text-red {
  color: #cd040b;
  font-size: 16px !important;
  display: none;
}
.green-text {
  color: $green;
}
//////////////////////
// Buttons
//////////////////////
.white-btn.complete-journey-btn {
  display: flex;
  width: fit-content !important;

  text-align: center;
  justify-content: center;
  border: 1px solid white !important;
  border-radius: 50px;
  font-family: "Verizon NHG DS";
  cursor: pointer;
  font-size: 16px !important;
  font-weight: 700;
  background-color: white;
  padding: 11.8px 24px !important;
  color: black;
  width: 148px;
  max-width: 148px;
  // line-height: 0;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  transition: all 0.25s ease;
  &:hover {
    transition: all 0.25s ease;
    background-color: transparent;
    color: white;
  }
}
.white-btn {
  display: inline-block;
  border: 2px solid white !important;
  border-radius: 50px;
  color: white;
  font-family: "Verizon NHG DS";
  font-size: 21px;
  padding: 16px 40px;
  // line-height: 0;
  text-align: center;
  text-decoration: none;
  transition: all 0.25s ease;
  &:hover {
    transition: all 0.25s ease;
    background-color: white;
    color: black;
  }
  &.small {
    font-size: 16px;
    padding: 12px 28px;
  }
}

.black-btn {
  display: inline-block;
  border: 1px solid black !important;
  border-radius: 50px;
  color: black;
  font-family: "Verizon NHG DS";
  font-size: 21px;
  padding: 16px 40px;
  // line-height: 0;
  text-align: center;
  text-decoration: none;
  transition: all 0.25s ease;
  &:hover {
    transition: all 0.25s ease;
    background-color: black;
    color: black;
  }
  &.small {
    font-size: 18px;
    padding: 12px 28px;
  }
}

.white-link {
  color: white;
  text-decoration: none;
  font-family: "Verizon NHG DS";
  font-weight: bold;
  font-size: 24px;
  padding-right: 34px;
  position: relative;
  color: white;
  transition: color 0.25s ease;
  display: inline-block;
  position: relative;
  &:after {
    content: "";
    height: 26px;
    width: 26px;
    position: absolute;
    right: -3px;
    // background-color: #3E86C4;
    background-image: url("../images/carrot-right-wht.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:hover {
    color: var(--green);
    transition: color 0.25s ease;
    &:after {
      background-image: url("../images/carrot-right-grn.svg");
    }
  }
  &.blk {
    &:hover {
      color: black;
      &:after {
        background-image: url("../images/carrot-right-blk.svg");
      }
    }
  }
  &.small {
    font-size: 16px;
    padding-right: 22px;
    &:after {
      content: "";
      height: 15px;
      width: 15px;
      position: absolute;
      right: 0px;
      background-image: url("../images/carrot-right-wht.svg");
    }
  }
}

.green-link {
  color: var(--green) !important;
  text-decoration: none;
  font-family: "Verizon NHG DS";
  font-weight: bold;
  font-size: 24px;
  padding-right: 34px;
  position: relative;
  color: white;
  transition: color 0.25s ease;
  display: inline-block;
  position: relative;
  &:after {
    content: "";
    height: 26px;
    width: 26px;
    position: absolute;
    right: -3px;
    // background-color: #3E86C4;
    background-image: url("../images/carrot-right-grn.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:hover {
    color: black !important;
    transition: color 0.25s ease;
    &:after {
      background-image: url("../images/carrot-right-blk.svg") !important;
    }
  }
  &.small {
    font-size: 16px;
    padding-right: 22px;
    &:after {
      content: "";
      height: 15px;
      width: 15px;
      position: absolute;
      right: 0px;
      background-image: url("../images/carrot-right-grn.svg");
    }
  }
}

//////////////////////
// Menu Nav
//////////////////////

.section-nav {
  position: fixed;
  top: 50%;
  left: 10px;
  transform: translate(-50%, -50%);
  z-index: 10;
  &.gray {
    li {
      a {
        // background-color: rgb(236, 18, 18);
        background-color: gray;
        transition: background-color 0.5s ease;
      }
    }
  }
  li {
    list-style-type: none;
    margin-bottom: 15px;
    position: relative;
    a {
      height: 30px;
      width: 3px;
      display: block;
      background-color: white;
      position: relative;
      transition: background-color 0.5s ease;
      &:after {
        content: "";
        // background-color: pink;
        position: absolute;
        width: 10px;
        height: 100%;
        top: 0;
        pointer-events: all;
      }
      &.active {
        background-color: $green;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

//////////////////////
// Menu Bar
//////////////////////

nav.menu-bar {
  padding: 0px 30px;
  // width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 64.66px;
  background-color: black;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  @media only screen and (max-width: 767px) {
    padding: 10px 15px 10px 30px;
  }
  @media only screen and (max-width: 680px) {
    height: 100%;
    max-height: 56px;
    padding: 5px 17px 5px 17px;
  }
  .logo-lockup {
    img {
      max-width: 156px;
      margin-top: 12px;
      margin-bottom: 12px;
      @media (max-width: 680px) {
        max-width: 119.84px;
      }
    }
    @media only screen and (max-width: 512px) {
      max-width: 40%;
    }
  }
  .button-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 767px) {
      .white-btn.small {
        padding: 7px 28px;
        max-width: 70px;
      }
    }
    @media only screen and (max-width: 512px) {
      width: fit-content;
      justify-content: space-between;
      a {
        font-size: 14px;
      }
    }
  }
}

//////////////////////
// Header
//////////////////////
#section0 {
  h1 {
    color: white;
  }
  .content-wrap {
    max-width: 940px;
  }
}

//////////////////////
// Masthead
//////////////////////
.masthead {
  position: relative;
  justify-items: left;
  display: grid;
  // grid-gap: 30px;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(100px, 1fr) 0px minmax(100px, 1440px) minmax(60px, 1fr);
  grid-template-areas: "... ... masthead ...";
  background: black;
  background-size: cover;
  background-position: unset;
  max-height: 100%;
  @media only screen and (max-width: 950px) {
    grid-template-columns: minmax(45px, 1fr) 0px minmax(100px, 1440px) minmax(45px, 1fr);
  }

  .hero-p {
    transition-delay: 0.65s !important;
  }

  h1 {
    color: white;
    font-size: 96px;
    letter-spacing: -1px;
  }

  .logo {
    max-width: 480.38px;
    @media only screen and (max-width: 800px) {
      max-width: 165px;
    }
  }
  .bg-grad {
    display: none;
    @media only screen and (max-width: 767px) {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba($color: #000000, $alpha: 0.3);
      display: block;
    }
  }
  .container {
    grid-area: masthead;
    padding-top: 108px;
    padding-bottom: 10%;
    z-index: 1;
    @media only screen and (max-width: 767px) {
      margin-top: 0px;
    }
  }
  p {
    color: white;
    max-width: 63%;
    font-size: 32px;
    @media only screen and (max-width: 1100px) {
      font-size: 28px;
    }
    @media only screen and (max-width: 950px) {
      font-size: 26px;
    }
    margin-top: 0px !important;
    letter-spacing: 0.25px;
    line-height: 36px;
    font-weight: normal !important;
    opacity: 0;
    &.active {
      opacity: 1;
      transition: opacity 1s ease;
    }
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 60px minmax(100px, 1fr) 40px;
    grid-template-areas: "... masthead ...";
    p {
      color: white;
      max-width: 90%;
    }
  }
  @media only screen and (max-width: 767px) {
    grid-template-columns: 45px minmax(100px, 1fr) 45px;

    p {
      font-size: 20px;
      line-height: 24px;
    }
  }

  @media only screen and (max-width: 680px) {
    grid-template-columns: 17px minmax(100px, 1fr) 17px;
  }
}

//////////////////////
// Lead with Experience
//////////////////////

.lead-with-experience {
  background-color: black;
  color: white;
  position: relative;
  z-index: 100;
  max-height: 1000px;
  .wrapper {
    left: 0;
    right: 0;
    margin: auto;
    justify-items: left;
    display: grid;
    // grid-gap: 30px;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(0px, 0px) minmax(0px, 34.5%) minmax(0px, 100%) minmax(120px, 1fr);
    // grid-auto-rows: 680px;
    grid-template-areas: "... image content ...";
    @media only screen and (max-width: 950px) {
      grid-template-columns: minmax(0px, 0px) minmax(0px, 40%) minmax(0px, 100%) minmax(45px, 1fr);
    }

    @media only screen and (max-width: 680px) {
      grid-template-columns: minmax(0px, 0px) minmax(0px, 34.5%) minmax(0px, 100%) minmax(45px, 1fr);
    }
  }
  .overlay {
    @media only screen and (max-width: 767px) {
      z-index: 10;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.4);
    }
  }
  .image-wrap {
    grid-area: image;
    width: 100%;
    background-position: top;
    max-height: 1000px;
    @include aspect-ratio(600px, 656px);
    @media only screen and (max-width: 950px) {
      width: calc(100% + 100px);
      max-height: 100%;
      position: relative;
      left: -45px;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      // max-height: 600px;
      // position: relative;
      left: 0px;
      position: absolute;
      height: 100%;
    }
  }
  .content-wrap {
    grid-area: content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15%;
    margin-top: 57px;
    margin-bottom: 63px;
    @media only screen and (max-width: 950px) {
      padding-left: 20% !important;
    }
    @media only screen and (max-width: 767px) {
      padding-left: 0px !important;
    }
    @media only screen and (max-width: 767px) {
      z-index: 50;
    }
    .flex-items {
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 767px) {
        margin-top: 40px;
        row-gap: 20px;
      }
      .item {
        flex-direction: row;
        display: flex;
        column-gap: 16px;
        row-gap: 16px;
        img {
          max-width: 20px;
        }
        @media only screen and (max-width: 767px) {
          flex-direction: row;
          img {
            max-width: 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .wrapper {
      grid-template-columns: 45px minmax(100px, 1fr) 45px;
      grid-template-areas:
        "... image ..."
        "... content ...";
      p {
        color: white;
        max-width: 93%;
      }
      .content-wrap {
        padding-left: 0;
      }
    }
  }
  @media only screen and (max-width: 680px) {
    .wrapper {
      grid-template-columns: 17px minmax(100px, 1fr) 17px;
    }
  }
}

.title {
  width: 100%;
  align-items: start;
  p {
    &.retail {
      color: black !important;
    }
    text-align: left;
    color: white;
    font-family: "Verizon NHG DS";
    font-weight: bold;
    font-size: 36px;
    transition: color 0.25s ease;
    padding-left: 10px;
    @media (max-width: 1300px) {
      font-size: 32px;
    }
    @media (max-width: 1250px) {
      font-size: 28px;
    }
    @media (max-width: 1150px) {
      font-size: 28px;
    }
    @media (max-width: 1100px) {
      font-size: 24px;
    }
    @media (max-width: 1000px) {
      font-size: 24px;
    }
    @media (max-width: 1000px) {
      font-size: 22px;
    }
    @media (max-width: 800px) {
      font-size: 24px;
    }
  }
  &.menu {
    position: absolute;
    top: -120px;
    @media (max-width: 900px) {
      top: -90px;
    }
    @media (max-width: 800px) {
      top: -80px;
    }
    &.mobile {
      p {
        font-size: 22px;
      }
    }
    p {
      padding-left: 0px;
    }
  }
}

//////////////////////
// See in Action
//////////////////////
.content-overlay {
  visibility: hidden;
  max-width: 100%;
  &.retail {
    visibility: visible;
  }

  &.mobile {
    @media (max-width: 800px) {
      position: fixed !important;
      height: 100vh;
      width: 100vw;
      top: 0px;
    }
  }
  position: absolute;
  background-color: transparent;
  top: 0;
  border-radius: 10px;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  transition: all 0s !important;
  display: grid;
  .tab-container {
    display: flex;
    height: 100%;
    min-height: 100%;
    flex-direction: row;
    color: white;
    grid-row: 1;
    border-bottom: 1px solid #d8dada;
    grid-column: 1 / span 2;
    column-gap: clamp(4px, 62px, 62px);
    width: 100%;
    @media(max-width: 1050px) {
      column-gap: clamp(4px, 45px, 45px);
    }
    @media(max-width: 800px) {
      column-gap: 25px;
      width: fit-content;
      margin-left: 0px;
      flex-wrap: nowrap;
      grid-row: 1;
    }
    @media only screen and (max-width: 800px) {
      position: relative;
      top: -5px;
    }
    @media only screen and (max-width: 680px) {
      column-gap: 15px;
      width: fit-content;
      // margin-left: 40px;
      flex-wrap: nowrap;
      grid-row: 1;
    }
    @media only screen and (max-width: 300px) {
      column-gap: 8px;
    }
    .tab {
      height: calc(100%);
      min-width: fit-content;
      @media (max-width: 1300px) {
        // min-width: 60px;
      }

      &:hover {
      }
      position: relative;
      // background-color: ;
      transition: all 0.5s ease-out;
      cursor: pointer;
      color: #6f7171;
      @media only screen and (max-width: 800px) {
        margin-top: 15px;
        width: fit-content;
        background-color: transparent;
        color: rgba(111, 113, 113, 0.54);
        align-item: flex-end;
        max-height: 35px;
        transition: all 0.3s;
      }
      &.active {
        color: black;
        &::after {
          width: 100%;
          @media(max-width: 800px) {
            border-bottom: 4px solid #ee0000;
          }
        }
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0; /* Start with no underline */
        border-bottom: 6px solid #ee0000; /* Adjust the thickness and color as needed */
        transition: width 0.5s ease; /* Animate the width change */
      }
      &:hover {
        color: black;
        &:after {
          // width: 100%; /* Full width on hover or when active */
        }
      }

      .tab-copy {
        left: 0;
        right: 0;
        margin: auto;
        top: 18px;
        cursor: pointer;
        font-family: "Verizon NHG DS";
        width: fit-content;
        font-size: 30px;
        font-weight: 700;
        @media (max-width: 1300px) {
          font-size: 24px;
        }
        @media only screen and (max-width: 800px) {
          text-orientation: unset;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: fit-content;
          transform: unset;
          writing-mode: unset;
          font-size: 24px;
        }
        @media only screen and (max-width: 680px) {
          text-orientation: unset;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: fit-content;
          transform: unset;
          writing-mode: unset;
          font-size: 16px;
        }
        @media only screen and (max-width: 500px) {
          font-size: 14px;

        }
        @media only screen and (max-width: 400px) {
          font-size: 12px;

        }
        @media only screen and (max-width: 300px) {
          font-size: 9.5px;
        }
      }
    }
  }
    
  grid-template-columns: minmax(0px, 47%) minmax(0px, 50%);
  grid-template-rows: 45px 45px; // Adjust as per your layout needs
  @media (max-width: 1300px) {
    // grid-template-columns: minmax(0px, 45%) minmax(0px, 55%);
  }
  @media (max-width: 1050px) {
    grid-template-columns: minmax(0px, 45%) minmax(0px, 55%);
  }
  @media (max-width: 950px) {
    grid-template-columns: minmax(0px, 40%) minmax(0px, 60%);
  }
  @media only screen and (max-width: 800px) {
    // grid-template-rows: 34% 59% 7%;
    grid-template-rows: 50px 190px 1fr; // Adjust as per your layout needs
    grid-template-columns: minmax(69x, 1fr);
  }
  &.mobile {
    @media (min-width: 680px) {
      display: none;
    }
    grid-template-rows: minmax(0px, 30%) minmax(0px, 70%);
    grid-template-columns: none;
    .content-overlay-menu {
      @media only screen and (max-width: 800px) {
        grid-row: 2;
        margin-top: 10px;
      }

      .menu-options {
        justify-content: center;
        height: fit-content;
        padding-top: 20px;
        row-gap: 10px;
        .menu-option {
          font-size: 20px;
          margin-left: 0px;
          height: 30px;
        }
      }
    }
    .content-overlay-content {
      @media only screen and (max-width: 800px) {
        grid-row: 3 / span 2 !important;
      }

      padding: 10%;
      @media (max-width: 800px) {
        height: 80%;
      }
    }
  }
  .content-overlay-menu {
    position: relative;
    grid-row: 2 / span 2;
    margin-top: 24px;
    @media only screen and (max-width: 950px) {
      margin-top: 15px;
    }
    @media only screen and (max-width: 800px) {
      margin-top: 0px;
      grid-column: 1 / span 2;
      grid-row: 2;
    }
    .close {
      background-image: url("../images/close-white.svg");
      background-size: cover;
      background-position: center;
      width: 15.8px;
      height: 15.8px;
      position: absolute;
      top: 30px;
      cursor: pointer;
      display: block;
      transition: all 0.35s ease-out;
      right: 0px;
      &:hover {
        transition: all 0.35s ease-out;
        transform: rotate(360deg) !important;
      }
    }
    .mobile-overlay {
      @media (max-width: 800px) {
        background-color: rgba(0, 0, 0, 0.15);
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        width: 100%;
        height: 100%;
      }
    }
    .menu-options {
      margin-top: 30px;
      padding-left: 40px;
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      &.manufacturing {
        &::after {
          background: #333333 !important;
        }
      }
      &::after {
        transition: all 0.3s;
        content: "";
        position: absolute;
        left: 24px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        z-index: -1;
        height: 100%;
        background: #d8dada;
      }

      @media (max-width: 900px) {
        margin-top: 60px;
      }
      @media (max-width: 800px) {
        margin-top: 30px;
        row-gap: 20px;
        padding-left: 40px;
      }
      @media only screen and (max-width: 680px) {
        row-gap: 12px;
      }
      .menu-option {
        font-family: "Verizon NHG DS";
        display: block;
        width: fit-content;
        &.retail {
        }
        font-size: 20px;
        font-weight: bold;
        height: 40px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #a7a7a7;
        padding-left: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 24px;
        position: relative;
        transition: all 0.3s;
        cursor: pointer;
        @media (max-width: 1000px) {
          font-size: 16px;
        }
        @media (max-width: 800px) {
          font-size: 14px;
          height: 20px;
        }

        &.retail,
        &.healthcare,
        &.education {
          color: #6f7171;

          &:hover:not(.active) {
            // background: rgba(0, 0, 0, 0.55);
            color: black;
            // &::after {
            //   background: rgba(0, 0, 0, 0.55);
            // }
          }
          &.active {
            color: black;
            &::after {
              background: #ee0000;
            }
          }
        }
        &:hover:not(.active) {
          color: white;
          &::after {
            // content: "";
            // position: absolute;
            // left: -16px;
            // z-index: 5;
            // top: 0;
            // bottom: 0;
            // width: 4px;
            // height: 100%;
            // background: rgba(255, 255, 255, 0.55);
          }
        }
        &.active {
          color: rgba(255, 255, 255, 1);
          &::after {
            transition: all 0.3s;
            content: "";
            position: absolute;
            left: -16px;
            top: 0;
            bottom: 0;
            animation: drawBorderVertical 0.4s ease forwards;
            width: 4px;
            height: 100%;
            background: #ee0000;
          }
          //   :after {

          //     width: 5px;
          //     height: 100%;
          //     border: 2px solid blue;
          //   transition: all 0.3s ease-out;
          //   border-left: 4px solid white;
          // }
        }
      }
    }
  }
  .content-overlay-content {
    display: flex;
    justify-content: center;
    height: auto;
    flex-direction: column;
    padding: 14%;
    padding-left: 96px;
    padding-right: 0px;
    max-width: 500px !important;
    margin-right: 55px;
    margin-top: 24px;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    left: -10px;
    max-width: 100%;
    grid-row: 2 / span 2;
    @media (max-width: 1300px) {
      padding: 0%;
      padding-left: 11%;
      padding-right: 0px;

    }
    @media (max-width: 1250px) {
      max-width: 80%;
      margin-right: 10px;
    }
    @media (max-width: 1000px) {
      max-width: 100%;
    }
        @media only screen and (max-width: 950px) {
      margin-top: 15px;
    }
    @media (max-width: 900px) {
      max-width: 100%;
    }
    @media (max-width: 800px) {
      margin-right: 0px;
      padding: 0;
      max-width: unset !important;
      margin-top: 0px;
      left: 0px;
      border: none;
      border-radius: 10px !important;
      justify-content: start;
      padding-top: 7%;
      grid-row: 3;
      grid-column: 1 / span 2;
    }
    @media (max-width: 800px) {
      max-width: 100%;
    }

    .content {
      width: auto;
      // padding-right: 12%;
      @media (max-width: 800px) {
        padding-left: 0px;
        padding-right: 0px;
      }
      h2 {
        opacity: 0;
        transition: all 0s ease-out;
        transform: translateY(40px);
        pointer-events: none;
      }
      p {
        overflow: hidden;
        opacity: 0;
        transition: all 0s ease-out;
        transform: translateY(40px);
        pointer-events: none;
        &.two {
          margin-top: 8px;
          transform: translateY(30px);
        }
      }
      position: absolute;
      &.active {
        position: relative;

        h2 {
          display: block;
          pointer-events: all;
          transform: translateY(0%);
          transition: all 0.5s ease-out;
          opacity: 1;
        }
        p {
          display: block;
          pointer-events: all;
          transform: translateY(0%);
          transition: all 0.5s ease-out;
          opacity: 1;
          transition-delay: 250ms !important;

          &.two {
            transition-delay: 500ms !important;
          }
        }
      }
    }
    @media (max-width: 1250px) {
      // padding-top: 10%;
      // padding-bottom: 10%;
    }
    .next-btn {
      @media (max-width: 800px) {
        padding-left: 0px;
      }
      @media (max-width: 680px) {
        // bottom: 15px;
      }
      margin-top: 20px;
      width: fit-content;
      height: fit-content;
      position: relative;
      cursor: pointer;
      padding-right: 23px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.45s ease-out !important;
      &.active-btn {
        opacity: 1;
        transition: opacity 0.45s ease-out !important;
        pointer-events: all;
      }
      p {
        font-size: 16px !important;
        @media (max-width: 680px) {
          font-size: 16px !important;
        }
      }
      // bottom: 10%;
      z-index: 10;
      p {
        margin-top: 0px;
        margin-bottom: 0px;
      }
      font-weight: 600;
      //   &::before {
      //     content: "";
      //     position: absolute;
      //     bottom: -5px;
      //     left: 0;
      //     width: 0;
      //     height: 2px;
      //     background: currentColor;
      //     transition: width 0.35s ease-out;
      // }

      // &:hover::before {
      //     width: calc(100% - 5px);
      // }
      &:hover {
        transition: transform 0.2s ease-out;
        &:after {
          transform: translateX(2.5px);
          @media (max-width: 800px) {
            transform: translateX(2.5px);
          }
          transition: transform 0.2s ease-out;
        }
      }

      &:after {
        content: "";
        transition: transform 0.3s ease-out;
        width: 16px;
        height: 24px;
        // transition: all 0.5s ease-out;
        position: absolute;
        right: 0px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        display: flex;
        flex-direction: row;
        @media (max-width: 800px) {
          width: 12px;
          height: 20px;
        }
        background-image: url("../images/right-caret.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .close {
      background-image: url("../images/close.svg");
      background-size: cover;
      background-position: center;
      width: 15.8px;
      height: 15.8px;
      position: absolute;
      z-index: 100;
      @media (max-width: 1100px) {
        top: 10px;
      }
      @media (max-width: 800px) {
        width: 15.8px;
        height: 15.8px;
      }
      @media (max-width: 1000px) {
        width: 12.8px;
        height: 12.8px;
        top: 10px;
      }
      @media (max-width: 800px) {
        top: 30px;
      }
      top: 30px;
      cursor: pointer;
      right: 12%;
      transition: all 0.35s ease-out;
      &:hover {
        transition: all 0.35s ease-out;
        transform: rotate(180deg) !important;
      }
    }
    p {
      &.bold {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: normal;
      }
      font-size: 14px;
      line-height: 18px;
      @media (max-width: 1300px) {
        font-size: 13.5px;
      }
      @media (max-width: 1200px) {
        font-size: 13px;
        line-height: 17px;
      }
      @media (max-width: 1150px) {
        font-size: 11px;
        line-height: 16px;
        max-width: unset;
      }

      @media (max-width: 1050px) {
        font-size: 11px;
      }
      @media (max-width: 900px) {
        font-size: 11px;
        line-height: 14.5px;
      }
      @media (max-width: 800px) {
        font-size: 14px;
        line-height: 18px;
      }

      @media (max-width: 680px) {
        font-size: 13px;
        line-height: 16px;
      }
      @media (max-width: 400px) {
        font-size: 11px;
        line-height: 14.8px;
      }
      font-size: 14px;
      letter-spacing: 0.5px;
      line-height: 18px;
    }
  }
  .overlay-bg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    // max-width: 614px;
    pointer-events: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    transition: all 0.4s ease;
    @media (max-width: 1050px) {
      // background-position: 100%;
      &.retail {
        // background-position: 70%;
      }
      &.education {
        // background-position: top;
      }
    }
    @media (max-width: 800px) {
      // background-position: center;
    }

    &.retail {
      background-image: url("../images/retail-large.png");
    }
    &.healthcare {
      background-image: url("../images/healthcare-large.png");
    }
    &.manufacturing {
      background-image: url("../images/warehouse-large.png");
    }
    &.education {
      background-image: url("../images/education-large.png");
    }
  }

  &.active {
    transform: translateX(-100%); /* move to the left by its own width */
  }
}
.partnership {
  background-color: white;
  color: black;
  position: relative;
  z-index: 100;
  max-height: 1000px;
  margin-bottom: 60px;

  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1440px) minmax(100px, 1fr);
  @media only screen and (max-width: 950px) {
    grid-template-columns: minmax(45px, 1fr) minmax(100px, 1440px) minmax(45px, 1fr);
  }
  @media only screen and (max-width: 767px) {
    border-top: none;

    grid-template-columns: minmax(0px, 1fr) minmax(100px, 1440px) minmax(0px, 1fr);
  }
  .black-btn {
    display: flex;
    width: fit-content !important;
    height: 44px !important;
    padding: 0 !important;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 144px !important;
    margin-bottom: 10px;
    white-space: no-wrap;
    border: 1px solid black;
    background: transparent;
    border-radius: 40px;
    font-weight: bold;
    font-size: 16px;
    @media only screen and (max-width: 767px) {
      border: 1px solid white !important;
      color: white !important;
      &:hover {
        color: black;
        background: white;
      }
    }
    &:hover {
      color: white !important;
      background: black;
    }
  }

  .content {
    border-top: 1px solid black;

    grid-column: 2 / span 1;

    @media only screen and (max-width: 767px) {
      margin-top: 73px;
    }

    .wrapper {
      padding-top: 73px;
      @media only screen and (max-width: 767px) {
        padding-bottom: 73px;
      }

      left: 0;
      right: 0;
      margin: auto;
      justify-items: left;
      display: grid;
      position: relative;
      // grid-gap: 30px;
      grid-template-columns: minmax(0px, 1fr) minmax(0px, 50%) minmax(0px, 100%) minmax(0px, 1fr);
      // grid-auto-rows: 680px;
      grid-template-areas: "... image content ...";
      @media only screen and (max-width: 950px) {
        grid-template-columns: minmax(0px, 1fr) minmax(0px, 50%) minmax(0px, 100%) minmax(0px, 1fr);
      }

      @media only screen and (max-width: 767px) {
        grid-template-columns: minmax(0px, 1fr) minmax(0px, 0%) minmax(0px, 100%) minmax(0px, 1fr);
      }

      @media only screen and (max-width: 680px) {
        grid-template-columns: minmax(17px, 1fr) minmax(0px, 0%) minmax(0px, 100%) minmax(17px, 1fr);
      }
    }
  }

  .overlay {
    @media only screen and (max-width: 767px) {
      z-index: 10;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.4);
    }
  }
  .image-wrap {
    grid-area: image;
    width: 100%;
    background-position: top;
    // max-height: 379px;
    @include aspect-ratio(603px, 379px);

    @media only screen and (max-width: 950px) {
      width: 100%;
      position: relative;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      // max-height: 600px;
      min-height: 100%;
      grid-area: unset;
      // position: relative;
      left: 0px;
      top: 0px;
      position: absolute;
      height: 100%;
    }
  }
  .content-wrap {
    grid-area: content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 9%;
    margin-top: 0px;
    max-width: 575px;
    h1 {
      margin-top: 0px;
    }
    @media only screen and (max-width: 950px) {
      padding-left: 6% !important;
      p {
        font-size: 20px !important;
      }
    }
    @media only screen and (max-width: 767px) {
      padding-left: 0px !important;
      p {
        font-size: 18px !important;
      }
      h1 {
        color: white;
      }
    }
    @media only screen and (max-width: 767px) {
      z-index: 50;
    }
    .flex-items {
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 767px) {
        margin-top: 40px;
        row-gap: 20px;
      }
      .item {
        flex-direction: row;
        display: flex;
        column-gap: 16px;
        row-gap: 16px;
        img {
          max-width: 20px;
        }
        @media only screen and (max-width: 767px) {
          flex-direction: row;
          img {
            max-width: 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .wrapper {
      grid-template-columns: 45px minmax(100px, 1fr) 45px;
      grid-template-areas:
        "... image ..."
        "... content ...";
      p {
        color: white;
        max-width: 93%;
      }
      .content-wrap {
        padding-left: 0;
      }
    }
  }
  @media only screen and (max-width: 680px) {
    .wrapper {
      grid-template-columns: 17px minmax(100px, 1fr) 17px;
    }
  }
}

.see-in-action {
  padding-bottom: 80px;
  background-color: white;

  justify-items: center;
  display: grid;
  grid-template-rows: 1;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1440px) minmax(100px, 1fr);
  grid-template-areas: "... content ...";
  @media (max-width: 950px) {
    grid-template-columns: minmax(45px, 1fr) minmax(100px, 1440px) minmax(45px, 1fr);
  }
  @media only screen and (max-width: 800px) {
    grid-template-columns: minmax(40px, 1fr) minmax(100px, 1440px) minmax(40px, 40px);
    // max-width: 600px;
    padding-top: 40px;
  }
  @media only screen and (max-width: 680px) {
    padding-top: 0px;
    grid-template-columns: minmax(17px, 1fr) minmax(100px, 1440px) minmax(17px, 1fr);
  }
  .see-it-in-action-p {
    padding-bottom: 18px;
    margin-top: 0px;
  }
  .content {
    color: black;
    display: grid;
    grid-area: content;
    min-height: 40px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    width: 100%;
    grid-template-areas: "inner-content";
    overflow: hidden;

    .inner-content {
      display: grid;
      // overflow: hidden;
      grid-gap: 27px;
      position: relative;
      min-height: 435px;
      grid-template-columns: minmax(100px, 100%) minmax(100px, 100%) minmax(100px, 100%) minmax(100px, 100%);
      @media (max-width: 1100px) {
        grid-gap: 15px;
        min-height: 400px;
      }
      @media (max-width: 950px) {
        grid-gap: 10px;
        min-height: 360px;
        grid-template-columns: minmax(45px, 100%) minmax(45px, 100%) minmax(45px, 100%) minmax(45px, 100%);
      }
      @media (max-width: 800px) {
        max-width: 600px;
        width: 100%;
        margin: auto;
        left: 0;
        right: 0;
      }
      @media (max-width: 680px) {
        margin: unset;
      }
      grid-template-areas: "discover define deploy deliver";
      p.indicators {
        position: relative;
        transition: color 0.5s ease;
        color: #707070;
        &:after {
          content: "";
          position: absolute;
          width: 0%;
          height: 4px;
          bottom: -10px;
          left: 0;
          background-color: var(--green);
          transition: width 1s ease;
        }
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          bottom: -10px;
          left: 0;
          background-color: #707070;
        }
      }

      div.phase {
        &.active {
          p {
            color: var(--green);
            transition: color 0.5s ease;
            &:after {
              width: 100%;
              transition: width 1s ease;
            }
          }
        }
        @media only screen and (max-width: 800px) {
          &.mobile-active {
            p {
              color: var(--green);
              transition: color 0.5s ease;
              &:after {
                width: 100%;
                transition: width 1s ease;
              }
            }
          }
        }
      }

      @media only screen and (max-width: 800px) {
        // grid-template-rows: 225px 225px 225px 225px;
        grid-template-rows: 164px 164px 164px 164px;
        grid-template-columns: 100%;
        grid-template-areas:
          "discover"
          "define"
          "deploy"
          "deliver";
      }
      @media only screen and (max-width: 680px) {
        grid-template-rows: 154px 154px 154px 154px;
        grid-template-columns: 100%;
        grid-template-areas:
          "discover"
          "define"
          "deploy"
          "deliver";
      }

      .card-wrap {
        @media (max-width: 800px) {
          max-width: 550px;
          padding-left: 20px;
          padding-top: 5px;
        }
        .mobile-details {
          margin-top: 0px !important;
          margin-bottom: 0px !important;
          margin-right: 0px;
          font-family: "Verizon NHG TX";
          font-style: normal !important;
          font-weight: 400;
          line-height: 16px;
          display: none;

          @media (min-width: 801px) {
          }
          @media (max-width: 800px) {
            font-size: 11px !important;
            display: block;
          }
          @media (max-width: 680px) {
            font-size: 10px !important;
          }
          @media (max-width: 320px) {
            font-size: 9px !important;
          }
          @media (max-width: 280px) {
            font-size: 7px !important;
          }
        }
        .mobile-overlay {
          @media (max-width: 800px) {
            background-color: rgba(0, 0, 0, 0.25);
            &.dark {
              background-color: rgba(0, 0, 0, 0.55);
            }
            z-index: 1;
            position: absolute;
            left: 0;
            top: 0;
            display: none;
            width: 100%;
            height: 100%;
          }
        }

        display: grid;
        grid-gap: 10px;
        border-radius: 10px;
        grid-template-rows: 70px 1fr;
        background-color: rgba(255, 255, 255, 0.15);
        height: 100%;
        clip-path: inset(0);

        flex-direction: column;
        align-items: center;
        cursor: pointer;
        position: relative;
        transition: all 0.3s ease-out;
        overflow: hidden !important;
        &:after {
          content: "";
          height: 20px !important;
          width: 20px;
          transition: all 0.5s ease-out;
          position: absolute;
          right: 21px;
          z-index: 2;
          bottom: 24px;
          height: 100%;
          display: flex;
          flex-direction: row;
          @media (max-width: 800px) {
            width: 13.33px;
            height: 13.33px;
            bottom: 16px;
          }
          background-image: url("../images/right-arrow.svg");
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        &:hover {
          &:after {
            transform: translateX(5px) scale(115%);
            @media (max-width: 800px) {
              transform: translateX(5px);
            }
            transition: all 0.3s ease;
          }
        }
        &:hover {
          .bg-wrapper {
            transform: scale(1.06);
            -webkit-transform: scale(1.06);
            clip-path: inset(0);
            @media (max-width: 800px) {
              transform: scale(1.02);
              -webkit-transform: scale(1.02);
            }

            transition: transform 0.5s ease-out;
          }
        }

        .bg-wrapper {
          position: absolute;
          height: 100%;
          width: 100%;
          overflow: hidden;
          z-index: 0;
          pointer-events: none;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 10px;
          transition: all 0.4s ease-out;
          @media only screen and (max-width: 800px) {
            background-position-y: 42%;
          }

          &.retail {
            background-image: url("../images/retail.png");
            @media (max-width: 800px) {
              background-image: url("../images/retail-small.png");
            }
          }
          &.healthcare {
            background-image: url("../images/healthcare.png");
            @media (max-width: 800px) {
              background-image: url("../images/healthcare-small.png");
            }
          }
          &.manufacturing {
            background-image: url("../images/warehouse.png");
            @media (max-width: 800px) {
              background-image: url("../images/warehouse-small.png");
            }
          }
          &.education {
            background-image: url("../images/education.png");
            @media (max-width: 800px) {
              background-image: url("../images/education-small.png");
            }
          }
        }
        .title p {
          z-index: 2;
          @media (max-width: 800px) {
            font-size: 20px;
            padding: 0px;
            margin-top: 14px;
            color: white !important;
            margin-bottom: 8px !important;
            &:after {
              height: 18px;
              width: 18px;
              top: 8px;
            }
          }
        }
      }
      .title {
        left: 22px;
        &.retail {
          color: black !important;
        }
        @media (max-width: 800px) {
          margin: unset !important;
          padding: 0px !important;
          height: inherit;
          width: auto !important;
          // margin-top: 20px !important;
        }
        grid-row: 1 / span 1;
        p {
          margin-top: 50px !important;
          position: relative;
          @media (max-width: 800px) {
            margin-top: 30px !important;
          }
        }
      }
      .number {
        grid-row: 2 / span 1;
        display: grid;
        justify-content: center;
        @media only screen and (max-width: 800px) {
          grid-row: 2 / span 1;
        }
      }
      .panel {
        display: grid;
        grid-row: 3;
        grid-column: 1 / span 5;
        grid-template-columns: minmax(10px, 1fr) minmax(10px, 1fr) minmax(10px, 1fr) minmax(10px, 1fr) minmax(10px, 1fr);
        grid-gap: 10px;
        background-color: white;
        z-index: 9;
        clip-path: inset(0 100% 0 0);
        transition: clip-path 1s ease;
        pointer-events: none;
        grid-template-areas: "panel-number panel-menu panel-menu panel-drawer panel-drawer";

        &.reveal {
          clip-path: inset(0);
          transition: clip-path 1s ease;
          z-index: 10;
          pointer-events: all;
        }
        @media only screen and (max-width: 800px) {
          &.mobile-reveal {
            clip-path: inset(0);
            transition: clip-path 1s ease;
            z-index: 10;
            pointer-events: all;
          }
        }

        @media only screen and (max-width: 800px) {
          grid-template-areas:
            "panel-number ..."
            "panel-menu panel-menu"
            "panel-drawer panel-drawer";
        }
      }

      .panel-number {
        display: grid;
        grid-area: panel-number;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 800px) {
          align-items: start;
        }
      }

      .discover-panel {
        @media only screen and (max-width: 800px) {
          grid-area: discovery-panel;
          grid-row: 3 / span 1;
          grid-column: 1 / span 1;
        }
      }
      .define-panel {
        @media only screen and (max-width: 800px) {
          grid-area: define-panel;
          grid-row: 5 / span 1;
          grid-column: 1 / span 1;
        }
        .panel-drawer {
          .drawer {
            background-color: var(--orange);
          }
        }
      }
      .deploy-panel {
        @media only screen and (max-width: 800px) {
          grid-area: deploy-panel;
          grid-row: 8 / span 1;
          grid-column: 3 / span 2;
        }
        .panel-drawer {
          .drawer {
            background-color: var(--blue);
          }
        }
      }
      .deliver-panel {
        @media only screen and (max-width: 800px) {
          grid-area: define-panel;
          grid-row: 10 / span 1;
          grid-column: 1 / span 2;
        }
        .panel-drawer {
          .drawer {
            background-color: var(--burnt);
          }
        }
      }

      .panel-menu {
        grid-area: panel-menu;
        @media only screen and (max-width: 800px) {
          grid-column: 1 / span 5;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 15px;
        }
      }
      .panel-drawer {
        grid-area: panel-drawer;
        @media only screen and (max-width: 800px) {
          grid-column: 1 / span 5;
        }
      }
    }
  }
  ////////////////////////////
  // Button and panel styles
  ////////////////////////////
  ///

  .inner-content {
    .card-wrap {
      border: 0;
      transition: all 0.4s ease-out !important;
      h1.circle {
        color: white;
        font-family: "Verizon NHG DS";
        font-weight: 400;
        font-size: 64px;
        border: 4px solid;
        height: 140px;
        width: 140px;
        line-height: 134px;
        border-radius: 100%;
        @media only screen and (max-width: 767px) {
          height: 100px;
          width: 100px;
          line-height: 100px;
        }
      }
      &.discover {
        grid-area: discover;
        grid-row: 1 / span 3;
        @media only screen and (max-width: 800px) {
          grid-row: 1 / span 1;
        }

        .number h1 {
          border-color: var(--green);
        }
      }
      &.define {
        grid-area: define;
        grid-row: 1 / span 3;
        @media only screen and (max-width: 800px) {
          grid-row: 2 / span 1;
        }

        .number h1 {
          border-color: var(--orange);
        }
      }
      &.deploy {
        grid-area: deploy;
        grid-row: 1 / span 3;
        @media only screen and (max-width: 800px) {
          grid-row: 3 / span 1;
        }

        .number h1 {
          border-color: var(--blue);
        }
      }
      &.deliver {
        grid-area: deliver;
        grid-row: 1 / span 3;
        @media only screen and (max-width: 800px) {
          grid-row: 4 / span 1;
        }

        .number h1 {
          border-color: var(--burnt);
        }
      }
      //////////////////////
      // Hover
      //////////////////////
      &:hover,
      &.reveal {
        background-color: white !important;
        transition: all 0.25s ease;
        &.retail {
          .title p {
            &:after {
            }
          }
        }
        &.healthcare {
          .title p {
            &:after {
            }
          }
        }
        &.manufacturing {
          .title p {
            &:after {
              background-image: url("../images/right-arrow-blue.svg");
            }
          }
        }
        &.education {
          .title p {
            &:after {
              background-image: url("../images/right-arrow-burnt.svg");
            }
          }
        }
      }
      @media only screen and (max-width: 800px) {
        &.mobile-reveal {
          background-color: white !important;
          transition: all 0.25s ease;
          &.discover {
            .title p {
              transition: color 0.25s ease;
              &:after {
                background-image: url("../images/right-arrow-green.svg");
              }
            }
            .number h1 {
              background-color: var(--green);
            }
          }
          &.define {
            .title p {
              transition: color 0.25s ease;
            }
            .number h1 {
              background-color: var(--orange);
            }
          }
          &.deploy {
            .title p {
              transition: color 0.25s ease;
            }
            .number h1 {
              background-color: var(--blue);
            }
          }
          &.deliver {
            .title p {
              transition: color 0.25s ease;
            }
            .number h1 {
            }
          }
        }
      }
    }
  }
}

//////////////////////////
// Smarter insights
//////////////////////////

.smarter-insights {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 1440px) minmax(124px, 1fr);
  background-color: white;
  padding-top: 59px;
  padding-bottom: 49px;
  @media only screen and (max-width: 950px) {
    grid-template-columns: minmax(45px, 1fr) minmax(45px, 1440px) minmax(60px, 1fr);
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: minmax(45px, 1fr) minmax(100px, 1440px) minmax(45px, 1fr);
  }

  @media (max-width: 680px) {
    padding-top: 40px;
    padding-bottom: 0px;

    grid-template-columns: minmax(17px, 1fr) minmax(100px, 1440px) minmax(17px, 1fr);
  }

  h1.title {
    grid-column: 2 / span 1;
    color: black;
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 30px;
    height: auto;
    width: 100%;
    max-width: 1127px;
    grid-column: 2 / span 1;
  }
  div.wrap {
    opacity: 0;
    &.active {
      opacity: 1;
      transition: opacity 1s ease;
    }
  }
  .content {
    display: grid;
    grid-template-rows: 1fr;
    margin-top: 40px;
    margin-bottom: 20px;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
    grid-gap: 60px;
    grid-column: 2 / span 1;
    color: black;
    @media only screen and (max-width: 1000px) {
      grid-gap: 30px;
    }
    @media only screen and (max-width: 925px) {
      grid-gap: 30px;
    }
    @media only screen and (max-width: 767px) {
      grid-gap: 34px;
    }
    .smarter-item {
      @media (max-width: 800px) {
        max-width: 550px;
      }
      @media (max-width: 680px) {
        column-gap: 25px;
        display: flex !important;
        flex-direction: row !important;
        &.one {
          column-gap: 24px;
          img {
            position: relative;
            left: 1px;
          }
        }
        &.two {
          column-gap: 22px;
          img {
            position: relative;
            left: 1px;
          }
        }
        &.four {
          img {
            position: relative;
            left: 12px;
          }
          column-gap: 39px;
        }
      }

      img {
        width: 47px;
        height: 47px;
        margin-bottom: 13px;
      }
      .smarter-icon-1 {
        width: 49px;
        height: 48px;
      }
      .smarter-icon-2 {
        width: 50px;
        height: 49px;
      }
      .smarter-icon-3 {
        width: 47px;
        height: 47px;
      }
      .smarter-icon-4 {
        width: 31px;
        margin-bottom: 4px;
        height: 51px;
      }
    }
    h1 {
      font-size: 20px !important;
      font-weight: bold;
      letter-spacing: normal !important;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 30px;
      @media (max-width: 680px) {
        font-size: 13.5px;
      }
    }
    div {
      div.flex {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        div {
        }
      }
    }
    div.one {
      grid-column: 1;
      display: flex;
      flex-direction: column;
    }
    div.two {
      grid-column: 2;
      display: flex;
      flex-direction: column;
    }
    div.three {
      grid-column: 3;
      display: flex;
      flex-direction: column;
    }

    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      div.one {
        grid-column: 1;
      }
      div.two {
        grid-column: 1;
      }
      div.three {
        grid-column: 1;
      }
      div.four {
        grid-column: 1;
      }
    }
  }
}

//////////////////////////
// Get Started
//////////////////////////

.get-started {
  padding-top: 72px;
  padding-bottom: 131px;
  // min-height: 500px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(100px, 1fr) 0px minmax(100px, 1440px) minmax(100px, 1fr);
  background-color: #f6f6f6;
  // padding-bottom: 300px;
  @media only screen and (max-width: 950px) {
    grid-template-columns: minmax(45px, 1fr) 0px minmax(100px, 1440px) minmax(45px, 1fr);
  }
  @media only screen and (max-width: 767px) {
    grid-template-columns: minmax(45px, 1fr) 0px minmax(100px, 1440px) minmax(45px, 1fr);
    padding-bottom: 46px;
  }
  @media (max-width: 680px) {
    grid-template-columns: minmax(17px, 1fr) 0px minmax(100px, 1440px) minmax(17px, 1fr);
  }
  p {
    font-size: 24px;
    padding-bottom: 20px;
    max-width: 80%;
    @media only screen and (max-width: 767px) {
      font-size: 20px;
      max-width: 100%;
    }
  }
  .text {
    grid-column: 3;
    color: black;
    @media only screen and (max-width: 767px) {
      grid-column: 2 / span 2;
    }
  }
  .form {
    grid-column: 3;
    font-family: "Verizon NHG DS";
    @media only screen and (max-width: 767px) {
      grid-column: 2 / span 2;
    }
  }
  .row {
    display: flex;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
    .col:first-of-type {
      margin-right: 30px;
      @media only screen and (max-width: 767px) {
        margin-right: 0;
      }
    }

    .col {
      width: 50%;
      max-width: 496px;
      margin-bottom: 25px;
      position: relative;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
      label {
        color: black;
        padding-bottom: 5px;
        display: block;
        letter-spacing: 0.03em;
        font-size: 12px;
      }
      input,
      select {
        // width: 100%;
        background-color: white;
        border: 0;
        height: 44px;
        color: black;
        border-radius: 4px;
        font-size: 16px;
        padding-left: 10px;
        // padding-right: 10px;
        display: block;
        width: -moz-available; /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        width: fill-available;
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;

        border-bottom: 1px solid black;
        -webkit-appearance: none !important;
        &:focus {
          outline: none;
        }
        &:focus-visible {
          outline: 1px solid white;
        }
      }
      select {
        padding: 5px 10px;
        height: 40px;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../images/arrow-down_wht.svg");
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-size: 6%;
        background-position-y: 9px;
      }

      input.black-btn {
        display: flex;
        width: fit-content;
        padding: 7px 24px;
        height: 44px;
        // padding: 0;
        text-align: center;
        justify-content: center;
        border: 1px solid black;
        background: transparent;
        border-radius: 40px;
        font-weight: bold;
        font-size: 16px;
        &:hover {
          color: white;
          background: black;
        }
      }
      input:-internal-autofill-selected {
        background-color: black !important;
        color: white !important;
      }
      /* Change the white to any color */
      input:-webkit-autofill,
      select:-webkit-autofill,
      input:-webkit-autofill:hover,
      select:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      select:-webkit-autofill:focus,
      input:-webkit-autofill:active,
      select:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px black inset !important;
        -webkit-text-fill-color: white !important;
        -webkit-appearance: none !important;
      }

      input.accepted {
        border-top: 1px solid $green;
        border-right: 1px solid $green;
        border-bottom: 1px solid $green;
        border-left: 1px solid $green;
      }
      input.unaccepted {
        border-bottom: 1px solid $red;
        border-top: 1px solid $red;
        border-right: 1px solid $red;
        border-left: 1px solid $red;
      }
    }
    .col.submit-col {
      align-items: center;
      padding-top: 8px;
      justify-content: end;
      display: flex;
      input {
        cursor: pointer;
      }
      @media (max-width: 757px) {
        justify-content: start;
      }
    }
  }
}

input.error,
select.error,
textarea.error {
  outline: none;
  border-color: red;
  border-width: 1px;
  border-style: solid;
  &:valid {
    border-style: auto;
    border-color: inherit;
  }
}

#my-form-status {
  @media (min-width: 680px) {
    position: absolute;
    width: 100%;
  }
}
